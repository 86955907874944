import React, {useContext, useState, useEffect} from "react";
import Switch from "react-switch";
import logo from "./asfalttipartio.png";
import "./App.css";
import {FirebaseContext, txCollectionPath} from "./Firebase";
import {downscaleImage} from "./io";

require("firebase/firestore");
require("firebase/storage");

const Form = () => {
    const firebase = useContext(FirebaseContext);
    const db = firebase.firestore();

    const [additionalInfo, setAdditionalInfo] = useState("");
    const [address, setAddress] = useState({
        addressLine: "",
        postalCode: "",
    });
    const [email, setEmail] = useState("");
    const [location, setLocation] = useState("");
    const [locationName, setlocationName] = useState("");
    const [phoneNumber, setPhonenumber] = useState("");
    // tilaajan nimi
    const [reporterName, setReporterName] = useState("");
    // tilaajan yritys
    const [reporterCompany, setReporterCompany] = useState("");
    const [customRepairTimeTime, setCustomRepairTimeTime] = useState("");
    const [customRepairTimeReasoning, setCustomRepairTimeReasoning] = useState(
        ""
    );
    const [customRepairTime, setCustomRepairTime] = useState(false);
    const [morning, setMorning] = useState(false);
    const [evening, setEvening] = useState(false);
    const [night, setNight] = useState(false);

    const [imageFiles, setImageFiles] = useState([]);

    const [submitFeedback, setSubmitFeedback] = useState();
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setSubmitting(true);

            // submitting might take a while if you have a lot of images
            // consider creating some sort of feedback that the form
            // is actually being sent.

            setSubmitFeedback("Lähetetään...");

            // create the document beforehand, we need the ID
            const doc = db.collection(txCollectionPath).doc();
            const id = doc.id;
            //console.log("doc", id);

            // upload images
            let index = 0;
            const downloadURLs = [];
            for (let image of imageFiles) {
                const ref = firebase
                    .storage()
                    .ref(id + "/" + id + "_" + index + ".jpg");
                index++;
                const result = await ref.put(image);
                //console.log("loop result", result);
                const compPath =
                    "gs://" +
                    result.metadata.bucket +
                    "/" +
                    result.metadata.fullPath;
                downloadURLs.push(compPath);
            }
            //
            // use downloadURLs as the documents imageReference field!
            //
            //console.log("after uploads, urls:", downloadURLs);

            // construct refId (postal code + characters from unique ID)
            const refId = address.postalCode + "-" + id.substring(0, 4);
            //console.log("refId", refId);

            // set data to document
            doc.set({
                additionalInfo: additionalInfo,
                address: address.addressLine,
                // claimedBy cannot be null, let's just set it to an empty string.
                claimedBy: "",
                customRepairTime: customRepairTime,
                customRepairTimeReasoning: customRepairTimeReasoning,
                customRepairTimeTime: customRepairTimeTime,
                email: email,
                imageReference: downloadURLs,
                key: id,
                location: location,
                postalCode: address.postalCode,
                locationName: locationName,
                offer: null,
                phoneNumber: phoneNumber,
                refId: refId,
                // reporter would be the users ID, since we're using anonymous login here
                // we might as well not include it
                reporter: "",
                reporterName: reporterName,
                reporterCompany: reporterCompany,
                review: null,
                state: 1,
                timeStamp: Date.now(),
                workReport: null,
            })
                .then(() => {
                    //console.log("Transaction submitted");
                    setSubmitFeedback("Tilaus lähetetty!");
                    emptyForm();
                    setSubmitting(false);
                })
                .catch((error) => {
                    //console.log("Error while submitting document", error);
                    setSubmitFeedback(
                        "Virhe lähettäessä tilausta " + error.message
                    );
                    setSubmitting(false);
                });
        }
    };

    const validateForm = () => {
        // validate email
        // source: https://emailregex.com/ (Javascript pattern)
        const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailValid = emailReg.test(email);
        if (email.length === 0 || !emailValid) {
            setSubmitFeedback("Tarkista sähköpostiosoite");
            return false;
        }
        return true;
    };

    useEffect(() => {
        function handleFiles() {
            const f = this.files;
            if (f) {
                const fs = [];
                for (let file of f) {
                    //console.log("a file", file);
                    // 1Mb
                    if (file.size > 1000000) {
                        //console.log("over 1Mb file");
                        // downscale images if they are over 1Mb in size
                        // a 2.5Mb file turned into a 77Kb file
                        // while testing, so I'd say it at least works
                        downscaleImage(file, (newFile) => {
                            fs.push(newFile);
                            // merge old and new files
                            setImageFiles([...imageFiles, ...fs]);
                        });
                    } else {
                        fs.push(file);
                        // merge old and new files
                        setImageFiles([...imageFiles, ...fs]);
                    }
                }
            } else {
                console.log("somethings wrong with the files");
            }
        }

        //console.log("attaching file handler...");
        // type="file" input is not handled by react, we're gonnna have to use
        // the file API & attach an event listener to the DOM element
        const elem = document.getElementById("fileInput");
        if (elem) {
            elem.addEventListener("change", handleFiles, false);
            //console.log("attached file handler");
        } else {
            console.log("%ccould not attach file handler!", "color:red");
        }
        // re-run every time imageFiles change so we don't have any stale data
        // if the user adds images more than once
    }, [imageFiles]);

    const emptyForm = () => {
        setPhonenumber("");
        setEmail("");
        setAddress({addressLine: "", postalCode: ""});
        setlocationName("");
        setAdditionalInfo("");
        setCustomRepairTime(false);
        setReporterName("");
        setReporterCompany("");
        setLocation("");
        setCustomRepairTimeTime("");
        setCustomRepairTimeReasoning("");
        setImageFiles([]);
    };

    function toggleRepairTimes(choice) {
        //console.log("toggling repair times");
        if (choice === "morning") {
            setMorning(!morning);
            if (evening) setEvening(false);
            if (night) setNight(false);
        } else if (choice === "evening") {
            setEvening(!evening);
            if (morning) setMorning(false);
            if (night) setNight(false);
        } else if (choice === "night") {
            setNight(!night);
            if (morning) setMorning(false);
            if (evening) setEvening(false);
        }
    }

    return (
        <div className="container">
            <div className="hero">
                <img src={logo} className="App-logo" alt="logo" />
                <h2>Ilmoita vaurioista Asfalttipartiolle</h2>
                <h4>
                    Täytä alla oleva lomake huolellisesti. Palaamme asiaan
                    mahdollisimman pian.
                </h4>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row-container">
                    <h3>Ilmoittajan tiedot</h3>

                    <div>
                        <input
                            type="text"
                            placeholder="Tilaajan nimi"
                            value={reporterName}
                            required
                            onChange={(e) => setReporterName(e.target.value)}
                        />
                    </div>

                    <div>
                        <input
                            type="text"
                            placeholder="Yrityksen nimi"
                            value={reporterCompany}
                            onChange={(e) => setReporterCompany(e.target.value)}
                        />
                    </div>

                    <div>
                        <input
                            type="text"
                            placeholder="Puhelinnumero"
                            value={phoneNumber}
                            required
                            onChange={(e) => setPhonenumber(e.target.value)}
                        />
                    </div>

                    <div>
                        <input
                            type="email"
                            placeholder="Sähköpostiosoite"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>

                <div className="row-container">
                    <h3>Kohteen tiedot</h3>

                    <div>
                        <input
                            type="text"
                            placeholder="Kohteen osoite, esim. Valtatie 43"
                            value={address.addressLine}
                            required
                            onChange={(e) =>
                                setAddress({
                                    addressLine: e.target.value,
                                    postalCode: address.postalCode,
                                })
                            }
                        />
                    </div>

                    <div>
                        <input
                            type="number"
                            placeholder="Kohteen postinumero, esim 90500"
                            value={address.postalCode}
                            required
                            onChange={(e) =>
                                setAddress({
                                    addressLine: address.addressLine,
                                    postalCode: e.target.value,
                                })
                            }
                        />
                    </div>

                    <div>
                        <input
                            type="text"
                            placeholder="Kohteen nimi, esim. ABC Oulu Tuira"
                            value={locationName}
                            required
                            onChange={(e) => setlocationName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="row-container">
                    <h3>Kuvat kohteesta</h3>

                    <div>
                        <button
                            id="fileSelect"
                            className="orange-button"
                            onClick={(e) => {
                                // prevent submit
                                e.preventDefault();
                                // click on the ugly input thats hidden from sight!
                                const elem = document.getElementById(
                                    "fileInput"
                                );
                                if (elem) {
                                    elem.click();
                                } else {
                                    console.log(
                                        "%cfile input not found!",
                                        "color:red"
                                    );
                                }
                            }}>
                            Lisää kuvia
                        </button>
                        <input //
                            type="file"
                            multiple // user can select multiple files
                            accept="image/*" // accept only images
                            id="fileInput"
                            style={{display: "none"}}
                        />
                    </div>

                    <div className="image-area">
                        {imageFiles.map((file, index) => {
                            //console.log("file list", file);
                            const imgUrl = URL.createObjectURL(file);
                            return (
                                <a
                                    href={imgUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={"image" + index}>
                                    <img
                                        alt=""
                                        src={imgUrl}
                                        style={{
                                            height: 200,
                                            width: 200,
                                            objectFit: "cover",
                                            borderRadius: "3px",
                                            margin: "5px",
                                        }}
                                    />
                                </a>
                            );
                        })}
                    </div>
                </div>

                <div className="additionalInfo">
                    <div>
                        <input
                            type="textarea"
                            placeholder="Lisätietoja kohteesta"
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                        />
                    </div>
                    <div>
                        <h3>Korjaaminen ei mahdollinen normaaliin aikaan</h3>
                        <p>
                            *Ajan valitseminen voi vaikuttaa lopulliseen hintaan
                        </p>

                        <div>
                            <Switch
                                onChange={() => {
                                    setCustomRepairTime(!customRepairTime);
                                }}
                                checked={customRepairTime}
                            />
                        </div>
                    </div>

                    {customRepairTime && (
                        <div>
                            <h4>Korjauksen aikatoive</h4>
                            <div className="toggle-wrap">
                                <div className="toggle-mobile-wrap">
                                    <div className="toggle">
                                        <label className="toggleLabel">
                                            Aamu
                                        </label>
                                        <Switch
                                            onChange={(value) => {
                                                toggleRepairTimes("morning");
                                                if (value) {
                                                    setCustomRepairTimeTime(
                                                        "Aamu"
                                                    );
                                                } else {
                                                    setCustomRepairTimeTime("");
                                                }
                                            }}
                                            checked={morning}
                                        />
                                    </div>

                                    <div className="toggle">
                                        <label className="toggleLabel">
                                            Ilta
                                        </label>
                                        <Switch
                                            onChange={(value) => {
                                                toggleRepairTimes("evening");
                                                if (value) {
                                                    setCustomRepairTimeTime(
                                                        "Ilta"
                                                    );
                                                } else {
                                                    setCustomRepairTimeTime("");
                                                }
                                            }}
                                            checked={evening}
                                        />
                                    </div>

                                    <div className="toggle">
                                        <label className="toggleLabel">
                                            Yö
                                        </label>
                                        <Switch
                                            label="Yö"
                                            onChange={(value) => {
                                                toggleRepairTimes("night");
                                                if (value) {
                                                    setCustomRepairTimeTime(
                                                        "Yö"
                                                    );
                                                } else {
                                                    setCustomRepairTimeTime("");
                                                }
                                            }}
                                            checked={night}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <input
                                    type="textarea"
                                    placeholder="Perustelu ajankohdalle"
                                    value={customRepairTimeReasoning}
                                    onChange={(e) =>
                                        setCustomRepairTimeReasoning(
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
                {submitFeedback && (
                    <div className="submit-feedback">
                        <p>{submitFeedback}</p>
                    </div>
                )}
                {!submitting && (
                    <input
                        type="submit"
                        className="btn-orange"
                        value="Lähetä ilmoitus"
                    />
                )}
            </form>
        </div>
    );
};

export default Form;
