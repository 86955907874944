import React, {useState, useEffect} from "react";
import "./App.css";
import Form from "./Form";
import Firebase, {FirebaseContext} from "./Firebase";
require("firebase/auth");

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!user) {
            //console.log("no user, signing in");
            Firebase.auth()
                .signInAnonymously()
                .catch(function (error) {
                    console.log("error while signing in anonymously", error);
                });
            Firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    //console.log("user is signed in", user);
                    //console.log("user id", user.uid);
                    setUser(user);
                } else {
                    //console.log("user is signed out");
                    setUser(null);
                }
            });
        }
    }, [user]);

    if (user) {
        return (
            <FirebaseContext.Provider value={Firebase}>
                <div className="App">
                    <Form />
                </div>
            </FirebaseContext.Provider>
        );
    } else {
        return (
            <div className="App">
                <p>Avaa ilmoituslomaketta...</p>
            </div>
        );
    }
}

export default App;
